import './application-skeleton.scss'
import {useState} from "react";
import {NavLink, Route, Routes} from "react-router-dom";
import {Avatar} from "antd";
import {PropertyList} from "../property/property-list";
import {Property} from "../property/property";
import {MembersList} from "../members/members-list";
import {Icon} from "../icons/icon";
import {Member} from "../members/member";
import {ViewUser} from "../users/user";
import {UsersList} from "../users/users-list";
import {ApplicationSkeletonComponentProps} from "../../models/application-skeleton/application-skeleton";

export const ApplicationSkeleton = (props: ApplicationSkeletonComponentProps)  => {

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    console.log(props.user)

    return (
        <>
            <div className={"app-overlay-menu-container " + (openMobileMenu ? "open": "")}>
                <div className={"menu-items-container"}>
                    <NavLink key={0} to={"/property"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Property"}</NavLink>
                    <NavLink key={1} to={"/members"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Members"}</NavLink>
                    <NavLink key={2} to={"/users"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Users"}</NavLink>
                </div>
            </div>
            <div className={"app-container"}>
                <div className={"menu"}>
                    <div className={"menu-button"}>
                        <button onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{openMobileMenu ? <Icon iconName={"Close"}/> : <Icon iconName={"Menu"}/>}</button>
                    </div>
                    <div className={"logo-container"}>
                        <NavLink key={0} to={"/"}>
                            <img src={"/kodus_beta_logo.svg"} alt="kodus_logo" />
                        </NavLink>
                    </div>
                    <div className={"menu-items-container"}>
                        <NavLink key={0} to={"/property"} className={"button"}>{"Property"}</NavLink>
                        <NavLink key={1} to={"/members"} className={"button"}>{"Members"}</NavLink>
                        <NavLink key={2} to={"/users"} className={"button"}>{"Users"}</NavLink>
                    </div>
                    <div className={"account-button-container"}>
                        <Avatar size={30}>{props.user.name.charAt(0)+props.user.lastName.charAt(0) }</Avatar>
                    </div>
                </div>
                <div className={"content"}>
                    <Routes>
                        <Route key={0} index={true} path={"/property"} element={<PropertyList/>}/>
                        <Route key={1} index={true} path={"/property/:id"} element={<Property/>}/>
                        <Route key={2} index={true} path={"/members"} element={<MembersList/>}/>
                        <Route key={3} index={true} path={"/member/:id"} element={<Member/>}/>
                        <Route key={4} index={true} path={"/users"} element={<UsersList/>}/>
                        <Route key={5} index={true} path={"/user/:id"} element={<ViewUser/>}/>
                    </Routes>
                </div>
            </div>
        </>
    );
}