import React, {useEffect, useState} from "react";
import {UpdateDebtFormFields, UpdateDebtsModalComponentProps} from "../../../models/debts/debt";
import {Button, Form, Input, Modal, notification, Select} from "antd";
import {FieldData} from "../../../models/filter-table/filter-table";
import {FormProps} from "antd/lib/form/Form";
import debtsService from "../../../services/debts-service";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

export const UpdateDebtModal = (props: UpdateDebtsModalComponentProps) => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const debtType = Form.useWatch('systemType', form);
    const [formData, setFormData] = useState<FieldData[]>([
        {name: ["id"], value: props.debt.id},
        {name: ["propertyId"], value: props.debt.propertyId},
        {name: ["systemType"], value: props.debt.systemType},
        {name: ["type"], value: props.debt.type},
        {name: ["sum"], value: props.debt.sum},
        {name: ["comment"], value: props.debt.comment},
    ]);

    useEffect(() => {
        setFormData([
            {name: ["id"], value: props.debt.id},
            {name: ["propertyId"], value: props.debt.propertyId},
            {name: ["systemType"], value: props.debt.systemType},
            {name: ["type"], value: props.debt.type},
            {name: ["sum"], value: props.debt.sum},
            {name: ["comment"], value: props.debt.comment},
        ])
    }, [props])

    const handleCancel = () => {
        props.close();
    }

    const onFinish: FormProps['onFinish'] = async (values) => {
        console.log(form.getFieldsValue());
        let status = await debtsService.update(form.getFieldsValue() as UpdateDebtFormFields);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while updating contact',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            props.close();
            props.recallData()
        }
    };

    const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) => {
    };

    return(
        <>
            {contextHolder}
            <Modal
            open={props.openCloseState}
            title="Изменение данных долга"
            onCancel={props.close}
            footer={[]}
            >
                <Form
                    form={form}
                    fields={formData}
                    name="editDebtModal"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{ width: "100%" }}
                >
                    <Form.Item name="id" hidden={true}>
                        <Input type={"number"}/>
                    </Form.Item>
                    <Form.Item name="systemType" label="Выберите общий тип долга" rules={[{ required: true }]}>
                        <Select>
                            <Option value={"membershipFee"}>Членский взнос</Option>
                            <Option value={"electricity"}>Элекстричество</Option>
                            <Option value={"waterKey"}>Ключь от скважены</Option>
                            <Option value={"other"}>Другое</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="type" label="Полное название">
                        <Input placeholder="" type={"text"} />
                    </Form.Item>
                    <Form.Item name="sum" label="Сумма долго">
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item name="lastPaidMetterData" label="Последние оплаченные показания" hidden={(debtType === "electricity" ? false : true)}>
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item name="connectionId" label="Выберите связанное подключение" hidden={(debtType === "electricity" ? false : true)}>
                        <Select>
                            <Option value={"123"}>123</Option>
                            <Option value={"132"}>132</Option>
                            <Option value={"213"}>213</Option>
                            <Option value={"312"}>312</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="comment" label="Пояснение">
                        <TextArea placeholder=""/>
                    </Form.Item>
                    <Form.Item name="propertyId" hidden={true}>
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Изменить</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}