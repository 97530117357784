import React, {useEffect, useRef, useState} from "react";
import {UploadConnectionPhotoComponentProps} from "../../../../models/connection/connection";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const UploadPhoto = ({connectionId, recallData}: UploadConnectionPhotoComponentProps) => {

    const [files, setFiles] = useState<FileList | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const callInput = () => {
        inputRef.current?.click();
    }

    useEffect(() => {
        uploadFiles()
    }, [files]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // setStatus('initial');
            console.log(e.target.files);
            setFiles(e.target.files);
            // uploadFiles();
        }
    }

    const uploadFiles = async () => {
        console.log("try to call uploadFiles");
        if (files) {
            console.log('Uploading file started...');

            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('file', files[i]);
                console.log(files[i])
            }

            try {
                setIsLoading(true);
                // You can write the URL of your server or any other endpoint used for file upload
                const result = await fetch('/api/v1/private/upload/connection-photos/'+connectionId, {
                    method: 'POST',
                    body: formData,
                });

                const data = await result.json();

                recallData()
                setIsLoading(false);
                console.log(data);
            } catch (error) {
                console.error(error);
            }
        }
    }

    return(
        <>
          <button onClick={() => callInput()} className={"uploadFileButton "+(isLoading ? "load" : "")}>Upload Image</button>
          <input ref={inputRef} type={"file"} name={"file"} accept="image/jpg" multiple onChange={handleInputChange} style={{display:"none"}}/>
        </>
    );
}