import React, {ReactElement} from "react";
import {ViewMeterReadingsComponentProps, MetersData} from "../../../models/meters-data/meters-data";
import {Connection} from "../../../models/connection/connection";
import {MetersReadingsTable} from "./meters-readings-table";



export const ViewMetersReadings = ({connections, recallData}: ViewMeterReadingsComponentProps) => {

    return(
        <div className="blocks-row">
            {connections.map((connection: Connection) => (
                <div key={connection.id} className="half-block">
                    <MetersReadingsTable connection={connection} recallData={() => {recallData()}}/>
                </div>
            ))}
        </div>
    );
}