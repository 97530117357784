import {
    AddContactPersonFormFields, AssignContactPersonToPropertyFields,
    UpdateContactPersonFormFields
} from "../models/contact-persons/contact-persons"

class ContactPersonService {

    constructor() {
    }

    async add(con: AddContactPersonFormFields, propertyId: number): Promise<any> {
        let response = await fetch("/api/v1/private/contact-person/", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                propertyId: propertyId,
                name: con.name,
                lastName: con.lastName,
                personalCode: con.personalCode,
                phone: con.phone,
                email: con.email,
                address: con.address,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async update(con: UpdateContactPersonFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/contact-person/"+con.id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                name: con.name,
                lastName: con.lastName,
                personalCode: con.personalCode,
                phone: con.phone,
                email: con.email,
                address: con.address,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async unlinkAssignToProperty(con: AssignContactPersonToPropertyFields): Promise<any> {
        let response = await fetch("/api/v1/private/contact-person/assign-to-property", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: JSON.stringify({
                propertyId: con.propertyId,
                contactPersonId: con.contactPersonId,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

}


let contactPersonService = new ContactPersonService()
export default contactPersonService;