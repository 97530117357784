import React, {ReactElement, useState} from "react";
import {FilterProps} from "../../../models/filter-table/filter-table";
import "./filter.scss"
import {Icon} from "../../icons/icon";
import {Tooltip} from "antd";
import {FiltersModal} from "./filters-modal";



export const Filter: React.FC<FilterProps> = ({recallData, filters}) => {

    const [openFiltersModal, setOpenFiltersModal] = useState(false);

    return(
        <>
        {filters !== null && filters?.length > 0 ?
            <div className={"filterTable__MainFiltersContainer"}>
                <div className={"filtersContainer"}>
                    <FiltersModal filters={filters} recallData={recallData as (filter: string) => void}/>
                </div>

                <Tooltip title="Filter data by columns">
                    <button className={"push-right"}><Icon iconName={"More"}/></button>
                </Tooltip>
            </div>
            : <></>
        }
        </>

    );
}