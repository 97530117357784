import {OwnersTableDropDownComponentProps} from "../../../models/owner/owner";
import React from "react";
import {Dropdown, MenuProps, Popconfirm, Space} from "antd";
import {Icon} from "../../icons/icon";
import {DebtsTableDropDownComponentProps} from "../../../models/debts/debt";
import debtsService from "../../../services/debts-service";

export const DebtsTableDropDown = (props: DebtsTableDropDownComponentProps) => {

    const deleteDebt = async (id: number) => {
        let status = await debtsService.delete(id);
        if (!status) {
            console.log();
        } else {
            props.recallData()
        }
    }

    const items: MenuProps['items'] = [
        {
            label: <Space style={{width: "100%"}} onClick={() => {props.openEditModal(props.debt)}}>Редактировать</Space>,
            key: '0',
        },
        {
            label: <Space style={{width: "100%"}} onClick={() => {deleteDebt(props.debt.id)}}>Удалить</Space>,
            key: '1',
        },
    ];

    return(
        <>
            <Dropdown menu={{items}} trigger={['click']}>
                <button>
                    <Icon iconName={"More"}/>
                </button>
            </Dropdown>
        </>
    );
}