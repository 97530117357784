import {useEffect, useState} from "react";
import {setTitle} from "../../helpers/helper";
import {PropertyFull} from "../../models/property/property";
import {useParams} from "react-router-dom";

export const Member = ()  => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(true);
    const [data, setData] = useState<PropertyFull>()
    let { id } = useParams();

    useEffect(() => {getProperty()},[]);

    const getData = () => {
        getProperty()
    }

    const getProperty = async (e?: React.FormEvent<HTMLFormElement>) => {

        setError(false);
        setLoading(true);
        // setPhotoLink(null);

        try {
            const response = await fetch("/api/v1/private/property/by-kadaster/"+id);
            console.log(response.url)
            if (!response.ok) {
                setError(true);
                return;
            }

            const responseData = await response.json();
            console.log(responseData);
            setData(responseData)
            setLoading(false);
            setError(false);
            setTitle("Property list")

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <h2>Member</h2>
        </>
    );
}