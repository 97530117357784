import React from "react";
import {Dropdown, MenuProps, Popconfirm, Space} from "antd";
import {Icon} from "../../icons/icon";
import {ContactPersonsTableDropDownProps} from "../../../models/contact-persons/contact-persons";

export const ContactPersonsTableDropDown = (props: ContactPersonsTableDropDownProps) => {

    const items: MenuProps['items'] = [
        {
            label: <Space style={{width: "100%"}} onClick={() => {props.openEditModal(props.contactPerson, props.propertyId)}}>Редактировать</Space>,
            key: '0',
        },
        {
            label: <Popconfirm
                    title="Отвязать от собственности"
                    description="Вы уверены в этом действии?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => {props.unlinkAssignToProperty({contactPersonId: props.contactPerson.id, propertyId: props.propertyId})}}
                >
                    <Space style={{width: "100%"}}>Отвязать от собственности</Space>
                </Popconfirm>,
            key: '1',
        },
    ];

    return(
        <>
            <Dropdown menu={{items}} trigger={['click']}>
                <button>
                    <Icon iconName={"More"}/>
                </button>
            </Dropdown>
        </>
    );
}