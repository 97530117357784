import {Button, Form, Input, Modal, notification, Select} from "antd";
import {useState} from "react";
import {FormProps} from "antd/lib/form/Form";
import contactPersonService from "../../../services/contact-person-service";
import {AddOwnerFormFields, AddOwnersFormComponentProps, UpdateOwnerFormFields} from "../../../models/owner/owner";
import ownerService from "../../../services/owner-service";
const { Option } = Select;

export const AddOwnerModal = (props: AddOwnersFormComponentProps)  => {

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleCancel = () => {
        setOpen(false);
    }

    const onFinish: FormProps<AddOwnerFormFields>['onFinish'] = async (values) => {
        console.log(values);
        let [status] = await Promise.all([ownerService.add(values, props.propertyId)]);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while adding new connection',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            props.recallData()
        }
    };

    const onFinishFailed: FormProps<AddOwnerFormFields>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {contextHolder}
            <div className={"row"} style={{margin: "30px 0 30px 0"}}>
                <Button type="primary" onClick={() => setOpen(true)}>Добавить нового владельца</Button>
            </div>
            <Modal
            open={open}
            title="Добавить нового владельца"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    name="addOwner"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item name="name" label="Имя">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="lastName" label="Фамилия">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="personalCode" label="Личный код">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="companyName" label="Название фирмы">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="companyRegisterNumber" label="Регистрационный код фирмы">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="contactPersonName" label="Имя контактного лица">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="contactPersonLastName" label="Фамилия контактного лица">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="phone" label="Телефон">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="email" label="Имейл">
                        <Input placeholder="" type={"email"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="address" label="Адрес">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="ownershipType" label="Тип владения" rules={[{ required: true }]}>
                        <Select>
                            <Option value="E">Частная земля (E)</Option>
                            <Option value="EH">Частная земля, право застройки (EH)</Option>
                            <Option value="R">Гос. собственность (R)</Option>
                            <Option value="RH">Право застройки (RH)</Option>
                            <Option value="S">Гос. собственность (S)</Option>
                            <Option value="OP">Опекун (OP)</Option>
                            <Option value="GD">Генеральная доверенность (GD)</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="ownershipShare" label="Доля (1/2 | 1/3 | 2/3)" rules={[{ required: true }]}>
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Изменить</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

}
