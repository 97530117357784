import './authorisation.scss'
import {Button, Form, Input, Tag, notification} from "antd";
import {FormProps, RequiredMark} from "antd/lib/form/Form";
import {useState} from "react";
import authorisationService from "../../services/authorisation-service";

export function Authorisation() {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    type FieldType = {
        username?: string;
        password?: string;
    };

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        let [loginStatus] = await Promise.all([authorisationService.login(values.username as string, values.password as string)]);
        if (!loginStatus) {
            form.resetFields(["password"]);
            api.error({
                message: 'Error',
                description:
                'Authorisation failed',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        }
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={"authorisationContainer"} style={{backgroundImage: "url('/img/1.jpg')"}}>
            <div className="fadeOutContainer">
                <div className={"advertContainer"}>
                </div>
                <div className={"formContainer"}>
                    <div className={"formBlock"}>
                        {contextHolder}
                        <img src={"/kodus_beta_logo.svg"} alt="kodus_logo" />
                        <p>Management system for garden and apartment associations</p>
                        <Form
                            form={form}
                            name="authorisation"
                            autoComplete="off"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item name="username" label="Username" required tooltip="User name is your email address" rules={[{ required: true }]}>
                                <Input placeholder="Username" />
                            </Form.Item>
                            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                                <Input placeholder="" type={"password"} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}