import React, {ReactElement} from "react";
import {IconsList} from "./icons-list";

export const Icon = ({ iconName }: { iconName: string }): ReactElement | null => {

    return(
        <>
            {IconsList[iconName]}
        </>
    );
}