import React, {ReactElement} from "react";
import {TableProps} from "../../../models/filter-table/filter-table";
import "./table.scss"
import {Pages} from "./pages/pages";
import {Icon} from "../../icons/icon";



export const Table = <T extends object>({data, columns, setPage, setViewOnPage}: TableProps<T>): ReactElement | null => {

    return(
        <div className={"filterTable__TableContainer"}>
            {data !== null ?
                <>
                    <table className={"listTable"}>
                        <colgroup>
                            {columns.map((column, index) => (
                                <col key={index} width={column.width}/>
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                {columns.map((column) => (
                                    <td key={column.index.toString()}>
                                        {column.name}
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map((row, index) => (
                                <tr key={index}>
                                    {columns.map((column) => (
                                        <td key={column.index.toString()} data-label={column.name}>{column.render ? column.render(row) : <>{row[column.index]}</>}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pages pagesCount={data.countOfPages} itemsCount={data.count} now={data.page} setPage={setPage}>
                        <select value={data.viewOnPage} onChange={setViewOnPage}>
                            <option key={1} value={10}>10</option>
                            <option key={2} value={20}>20</option>
                            <option key={3} value={30}>30</option>
                            <option key={4} value={40}>40</option>
                            <option key={5} value={50}>50</option>
                            <option key={6} value={60}>60</option>
                        </select>
                    </Pages>
                </>
                : ""}
        </div>

    );
}