import React, {useState} from "react";
import {Debt, DebtsSystemTypes, DebtsTableComponentProps, UpdateDebtFormFields} from "../../../models/debts/debt";
import debtsService from "../../../services/debts-service";
import {UpdateDebtModal} from "./edit-debt-modal";
import {DebtsTableDropDown} from "./debts-table-dropdown";

export const DebtsTable = (props: DebtsTableComponentProps) => {

    const [openEditDebtModal, setOpenEditDebtModal] = useState<boolean>(false)
    const [currentDebt, setCurrentDebt] = useState<Debt|null>(null)
    //
    const setDebtEditModal = (debt: Debt) => {
        console.log(debt)
        setCurrentDebt(debt);
        setOpenEditDebtModal(true);
    }

    const closeEditDebtModal = () => {
        setOpenEditDebtModal(false)
    }

    const updateDebt = async (data: UpdateDebtFormFields) => {
        let res = await debtsService.update(data)

        if (res) {
            props.recallData();
        }
    }

    const debtsTypes = {
        membershipFee: "Членский взнос",
        electricity: "Элекстричество",
        waterKey: "Ключь от скважены",
        other: "Другое",
    }

    return(
        <>
            <table className={"ownersTable"}>
                {currentDebt
                    ? <UpdateDebtModal
                        close={closeEditDebtModal}
                        openCloseState={openEditDebtModal}
                        debt={currentDebt}
                        recallData={() => props.recallData()}/>
                    : <></>
                }
                <thead>
                    <tr>
                        <td>Действия</td>
                        <td>Название</td>
                        <td>Сумма</td>
                        <td>Комментарий</td>
                        <td>Дата последнего изменения</td>
                    </tr>
                </thead>
                <tbody>
                {props.debts.map((debt, index) =>
                    <tr key={index}>
                        <td key={"action"} data-label={"Действия"}>
                            <DebtsTableDropDown debt={debt} openEditModal={() => setDebtEditModal(debt)} recallData={() => props.recallData()}/>
                        </td>
                        <td key={"type"} data-label={"Тип"}>{debt.type === "" ? DebtsSystemTypes[debt.systemType] : debt.type}</td>
                        <td key={"summ"} data-label={"Сумма"} style={{whiteSpace: "nowrap"}}>{debt.sum.toFixed(2).replace(".", ",")} €</td>
                        <td key={"comm"} data-label={"Комментарий"}>{debt.comment.split("\n").map((line) => <>{line}<br/></>)}</td>
                        <td key={"date"} data-label={"Дата последнего изменения"}>{debt.updatedAt.split("T")[0]}</td>
                    </tr>
                )}
                    <tr key={"totals"}>
                        <td></td>
                        <td><strong>Общее:</strong></td>
                        <td><strong style={{whiteSpace: "nowrap"}}>{props.debts.reduce((partialSum, a) => partialSum + a.sum, 0).toFixed(2).replace(".", ",")} €</strong></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}