import {UserDataForList} from "./user";
import {Column} from "../filter-table/filter-table";
import {NavLink} from "react-router-dom";

export const userTableColumns:Column<UserDataForList>[] = [
    {
        width: "250px",
        name: "Имя",
        index: "name",
        render: (data) => <NavLink to={"/user/"+data.id}>{data.name + " " + data.lastName}</NavLink>
    },
    {
        width: "250px",
        name: "Имейл",
        index: "email",
        render: (data) => <>{data.email}</>
    },
    {
        width: "250px",
        name: "Группа",
        index: "group",
        render: (data) => <>{data.group}</>
    }
]