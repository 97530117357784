import {Button, Form, Input, Modal, notification, Radio, Select} from "antd";
import {useState} from "react";
import {FormProps} from "antd/lib/form/Form";
import {AddConnectionModalProps, AddConnectionFormFields} from "../../../models/connection/connection";
import connectionService from "../../../services/connection-service";
import {Icon} from "../../icons/icon";
const { Option } = Select;

export const AddConnectionModal = (props: AddConnectionModalProps)  => {

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleCancel = () => {
        setOpen(false);
    }

    const onFinish: FormProps<AddConnectionFormFields>['onFinish'] = async (values) => {
        console.log(values);
        let [status] = await Promise.all([connectionService.add(values, props.propertyId)]);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while adding new connection',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            props.recallData()
        }
    };

    const onFinishFailed: FormProps<AddConnectionFormFields>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)} style={{paddingLeft: "4px"}}><Icon iconName={"Add"}/>Добавить подключение</Button>
            <Modal
            open={open}
            title="Добавить подключение"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    name="authorisation"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item name="connectionName" label="Название для подключения">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="connectionPoint" label="Точка подключения" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Уличная магистраль">Уличная магистраль</Option>
                            <Option value="Основная магистраль">Основная магистраль</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="connectionType" label="Тип подключения" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Воздушная линия (1 фаза)">Воздушная линия (1 фаза)</Option>
                            <Option value="Воздушная линия (3 фазы)">Воздушная линия (3 фазы)</Option>
                            <Option value="Подземная линия (1 фаза)">Подземная линия (1 фаза)</Option>
                            <Option value="Подземная линия (3 фазы)">Подземная линия (3 фазы)</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="connectionTypeComment" label="Комментарий для типа подключения">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="inputSwitchRating" label="Номинал вводного автомата" rules={[{ required: true }]}>
                        <Select>
                            <Option value="6 ампер">6 ампер</Option>
                            <Option value="8 ампер">8 ампер</Option>
                            <Option value="10 ампер">10 ампер</Option>
                            <Option value="12 ампер">12 ампер</Option>
                            <Option value="15 ампер">15 ампер</Option>
                            <Option value="16 ампер">16 ампер</Option>
                            <Option value="20 ампер">20 ампер</Option>
                            <Option value="25 ампер">25 ампер</Option>
                            <Option value="30 ампер">30 ампер</Option>
                            <Option value="32 ампера">32 ампера</Option>
                            <Option value="35 ампер">35 ампер</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="inputSwitchManufacturer" label="Производитель вводного автомата" rules={[{ required: true }]}>
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="inputSwitchSealType" label="Тип Пломбы на вводном автомате" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Нет пломбы">Нет пломбы</Option>
                            <Option value="Пластмассовая">Пластмассовая</Option>
                            <Option value="Пластмассовая с номером">Пластмассовая с номером</Option>
                            <Option value="Свинцовая">Свинцовая</Option>
                            <Option value="Пломбы нет">Пломбы нет</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="inputSwitchSealSerialNumber" label="Номер пломбы вводного автомата">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="hasElectricityMeterActOfReplacement" label="Есть акт замены счетчика?" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={true}>Да</Radio>
                            <Radio value={false}>Нет</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="electricityMeterState" label="Состояние счетчика" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Новый">Новый</Option>
                            <Option value="Средний">Средний</Option>
                            <Option value="Бывалый">Бывалый</Option>
                            <Option value="Старый (советский)">Старый (советский)</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="electricityMeterType" label="Тип счетчика" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Цифровой Однотарифный">Цифровой Однотарифный</Option>
                            <Option value="Цифровой Двутарифный">Цифровой Двутарифный</Option>
                            <Option value="Мини Однотарифный">Мини Однотарифный</Option>
                            <Option value="Мини Двутарифный">Мини Двутарифный</Option>
                            <Option value="Аналоговый Однотарифный">Аналоговый Однотарифный</Option>
                            <Option value="Аналоговый Двутарифный">Аналоговый Двутарифный</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="electricityMeterComment" label="Комментарий к счетчику">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="electricityMeterSerialNumber" label="Серийный номер счетчика">
                        <Input placeholder="" type={"text"}autoComplete="off" autoCapitalize="none" autoCorrect="off" />
                    </Form.Item>
                    <Form.Item name="electricityMeterSealType" label="Тип пломбы на счетчике" rules={[{ required: true }]}>
                        <Select>
                            <Option value="Нет пломбы">Нет пломбы</Option>
                            <Option value="Пластмассовая">Пластмассовая</Option>
                            <Option value="Пластмассовая с номером">Пластмассовая с номером</Option>
                            <Option value="Свинцовая">Свинцовая</Option>
                            <Option value="Пломбы нет">Пломбы нет</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="electricityMeterSealSerialNumber" label="Номер пломбы счетчика">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Submit</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}