export const setTitle = (title: string) => {
    console.log("set title");
    let docTitle = document.head.querySelector("title");
    if (docTitle !== null) {
        docTitle.innerText = title
    }
}

export const switchZoom = (zoomState: boolean): void => {
    let viewPortMeta = document.querySelector("meta[name='viewport']") as HTMLMetaElement

    if (viewPortMeta !== null) {
        if (!zoomState) {
            viewPortMeta.content = "width=device-width, initial-scale=1, maximum-scale=1"
        } else {
            viewPortMeta.content = "width=device-width, initial-scale=1"
        }
    }
}

export const blockBody = (blockState: boolean): void => {
    let body = document.querySelector("body");
    if (body !== null) {
        if (blockState) {
            body.style.overflowY = "hidden";
        } else {
            body.style.overflowY = "auto";
        }
    }
}