import {Property} from "./property";
import {Column} from "../filter-table/filter-table";
import {NavLink} from "react-router-dom";

export const columns:Column<Property>[] = [
    {
        width: "250px",
        name: "Адрес",
        index: "country",
        render: (data) => <NavLink to={"/property/"+data.kadasterNumber}>{data.street+" "+data.houseNumber}</NavLink>
    },
    {
        width: "250px",
        name: "Подключение",
        index: "connections",
        render: (data) => <>{data.connections !== undefined && data.connections !== null && data.connections?.length > 0 ? <>Есть</> : <>Нет</>}</>
    },
    {
        width: "120px",
        name: "Кадастр",
        index: "kadasterNumber",
        render: (data) => data.kadasterNumber
    }
]