export class UrlService {

    constructor() {
    }

    updateSearchParams(parameters:string[][]) {
        const url = new URL(window.location.href);
        // url.searchParams.set(key, value);

        parameters.forEach((value) => {
            url.searchParams.set(value[0], value[1]);
        })
        window.history.pushState(null, '', url.toString());


    }

    removeSearchParams(parameters:string[]) {
        const url = new URL(window.location.href);

        parameters.forEach((value) => {
            console.log("delete", value);
            url.searchParams.delete(value);
        })
        window.history.pushState(null, '', url.toString());
    }
}