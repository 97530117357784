import React from "react";
import {ShowPhotoPropsInterface} from "../../../models/file/file";
import {Icon} from "../../icons/icon";

export const PhotoModal = ({photoLink, closeHandler, open}: ShowPhotoPropsInterface) => {

    return(
        open
            ? <div className={"photoModal"}>
                <div className={"close"}>
                    <button onClick={() => closeHandler()}><Icon iconName={"Close"}/></button>
                </div>
                <img src={photoLink as string} alt={photoLink as string} />
            </div>
            : <></>

    );
}