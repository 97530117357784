import { Popconfirm } from "antd";
import React from "react";
import {MeterReadingsTableComponentProps} from "../../../models/meters-data/meters-data";
import {Icon} from "../../icons/icon";
import connectionService from "../../../services/connection-service";



export const MetersReadingsTable = ({connection, recallData}: MeterReadingsTableComponentProps) => {

    const deleteMeterReading = async (id: number) => {
        let res = await connectionService.deleteMeterReading(id)

        if (res) {
            recallData();
        }
    }

    return(
        <>
            <h3>{connection.connectionName}</h3>
            <table className={"meterReadingsTable"}>
                <thead>
                    <tr>
                        <td>Показания</td>
                        <td>Дата</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                {connection.metersData.map((reading, index) =>
                    <tr key={index}>
                        <td>{reading.meterReading}</td>
                        <td>{reading.createdAt.substring(0,10)}</td>
                        <td>
                            <Popconfirm
                                title="Удаление показаний"
                                description="Вы уверены в этом действии?"
                                okText="Да"
                                cancelText="Нет"
                                onConfirm={() => {deleteMeterReading(reading.id)}}
                            >
                                <button><Icon iconName={"Close"}/></button>
                            </Popconfirm>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    );
}