import {Button, Form, Input, Modal, notification} from "antd";
import {useEffect, useState} from "react";
import {FormProps} from "antd/lib/form/Form";
import {FieldData} from "../../../models/filter-table/filter-table";
import {ContactPersonEditForm, UpdateContactPersonFormFields} from "../../../models/contact-persons/contact-persons";
import contactPersonService from "../../../services/contact-person-service";

export const ContactPersonEditModal = ({ recallData, contactPerson, close, openCloseState }: ContactPersonEditForm)  => {

    console.log("contactPersonEditModal triggered", contactPerson);

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [formData, setFormData] = useState<FieldData[]>([
        {name: ["id"], value: contactPerson.id},
        {name: ["name"], value: contactPerson.name},
        {name: ["lastName"], value: contactPerson.lastName},
        {name: ["personalCode"], value: contactPerson.personalCode},
        {name: ["phone"], value: contactPerson.phone},
        {name: ["email"], value: contactPerson.email},
        {name: ["address"], value: contactPerson.address},
    ]);

    useEffect(() => {
        setFormData([
            {name: ["id"], value: contactPerson.id},
            {name: ["name"], value: contactPerson.name},
            {name: ["lastName"], value: contactPerson.lastName},
            {name: ["personalCode"], value: contactPerson.personalCode},
            {name: ["phone"], value: contactPerson.phone},
            {name: ["email"], value: contactPerson.email},
            {name: ["address"], value: contactPerson.address},
        ])
    }, [contactPerson])

    const handleCancel = () => {
        close();
    }

    const onFinish: FormProps['onFinish'] = async (values) => {
        console.log(form.getFieldsValue());
        close();
        let status = await contactPersonService.update(form.getFieldsValue() as UpdateContactPersonFormFields);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while updating contact',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            recallData()
        }
    };

    const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) => {
    };

    return (
        <>
            {contextHolder}
            <Modal
            open={openCloseState}
            title="Изменение данных контакного лица"
            onCancel={close}
            footer={[]}
            >
                <Form
                    form={form}
                    fields={formData}
                    name="edit-contact-person"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{ width: "100%" }}
                >
                    <Form.Item name="id" hidden={true}>
                        <Input type={"number"}/>
                    </Form.Item>
                    <Form.Item name="name" label="Имя">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="lastName" label="Фамилия">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="personalCode" label="Личный код">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="phone" label="Номер телефона">
                        <Input placeholder="" type={"phone"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="email" label="Адрес электронной почты">
                        <Input placeholder="" type={"email"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="address" label="Адрес проживания">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Submit</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}