import {Button, Form, Input, Modal, notification, Radio, Select, Space, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {FormProps} from "antd/lib/form/Form";
import {Icon} from "../../icons/icon";
import {decodeFilter, encodeFilter} from "../filter-table-helper";
import {FieldData, FilterModalProps} from "../../../models/filter-table/filter-table";
import {UrlService} from "../../../services/url";
import {useSearchParams} from "react-router-dom";
const { Option } = Select;



export const FiltersModal = ({ recallData, filters }: FilterModalProps)  => {

    const url = new UrlService();
    const [searchParams] = useSearchParams();
    const filter = (searchParams.get("filter") !== null ? searchParams.get("filter") as string : "{}");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [formData, setFormData] = useState<FieldData[]>(decodeFilter(filter));

    const handleCancel = () => {
        setOpen(false);
    }

    const resetFilter = () => {
        handleCancel();
        url.removeSearchParams(["filter"])
        setFormData([]);
        form.resetFields();
        recallData("");
    }

    const onFinish: FormProps['onFinish'] = async (values) => {
        console.log(form.getFieldsValue());
        let filter = encodeFilter(values)
        setFormData(decodeFilter(filter))
        url.updateSearchParams([["filter", filter]])
        setOpen(false);
        recallData(filter);
    };

    const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) => {
    };

    return (
        <>
            {contextHolder}
            <Tooltip title="Filter data by columns">
                <button onClick={() => {setOpen(true)}} className={(formData.length > 0 ? "active" : "")}>
                    <Icon iconName={"Filter"}/>
                </button>
            </Tooltip>
            <Modal
            open={open}
            title="Фильтр"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    fields={formData}
                    name="filter-table-filter"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{ width: "100%" }}
                >
                    {filters.map((filter, index) =>
                        filter.type === "input"
                            ? (
                                <Form.Item key={index} label={filter.name} style={{ width: '100%', margin: "0px 0 0 0" }}>
                                    <Space.Compact style={{ width: '100%', margin: 0 }}>
                                        {
                                            filter.operands !== null && filter.operands.length > 0
                                            ? <Form.Item name={[filter.fieldKey, 'operand']} style={{ width: '100px' }} initialValue={filter.operands[0].value}>
                                                <Select>
                                                    {filter.operands.map((operand, index) =>
                                                        <Option key={index} value={operand.value}>{operand.name}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            : <Form.Item  name={[filter.fieldKey, 'operand']} hidden={true} initialValue={"equal"}>
                                                <Input/>
                                            </Form.Item>
                                        }
                                        <Form.Item  name={[filter.fieldKey, 'value']} style={{ width: '100%'}}>
                                            <Input placeholder={filter.placeholder} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                                        </Form.Item>
                                    </Space.Compact>
                                </Form.Item>
                            )
                            : <></>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Применить</Button>
                        <Button onClick={resetFilter}>Очистить</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}