import React, {useState} from "react";
import {AddDebtsFormFields, AddDebtsModalComponentProps} from "../../../models/debts/debt";
import {Button, Form, Input, Modal, notification, Select} from "antd";
import debtsService from "../../../services/debts-service";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

export const AddDebtModal = (props: AddDebtsModalComponentProps) => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
        form.resetFields();
    }

    const onSubmit = async () => {
        console.log(form.getFieldsValue());
        let status = await debtsService.add(form.getFieldsValue() as AddDebtsFormFields);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while adding new debt',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            props.recallData()
        }
    }

    const onSubmitFail = () => {
        console.log(form.getFieldsValue())
    }

    return(
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)}>Добавить долг</Button>
            <Modal
            open={open}
            title="Добавить долг"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    name="addDebtModal"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onSubmit}
                    onFinishFailed={onSubmitFail}
                >
                    <Form.Item name="systemType" label="Выберите общий тип долга" rules={[{ required: true }]}>
                        <Select>
                            <Option value={"membershipFee"}>Членский взнос</Option>
                            <Option value={"electricity"}>Элекстричество</Option>
                            <Option value={"waterKey"}>Ключь от скважены</Option>
                            <Option value={"other"}>Другое</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="type" label="Полное название">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="sum" label="Сумма долго">
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item name="comment" label="Пояснение">
                        <TextArea placeholder="" autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="propertyId" hidden={true} initialValue={props.propertyId}>
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Подать</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}