import {Column} from "../filter-table/filter-table";
import {MemberDataForList} from "./member";
import {NavLink} from "react-router-dom";

export const memberTableColumns:Column<MemberDataForList>[] = [
    {
        width: "250px",
        name: "Имя",
        index: "name",
        render: (data) => <NavLink to={"/member/"+data.ownerId}>{data.name}</NavLink>
    },
    {
        width: "250px",
        name: "Адреса",
        index: "property",
        render: (data) => <>{data.property}</>
    },
    {
        width: "250px",
        name: "Фирма",
        index: "companyName",
        render: (data) => <>{data.companyName}</>
    }
]