import React, {useState} from "react";
import {
    AssignContactPersonToPropertyFields,
    ContactPerson,
    ContactPersonsTableTableInterface
} from "../../../models/contact-persons/contact-persons";
import {ContactPersonsTableDropDown} from "./contact-persons-table-dropdown";
import {ContactPersonEditModal} from "./edit-contact-person";
import contactPersonService from "../../../services/contact-person-service";

export const ContactPersonsTable = ({contactConnections, propertyId, recallData}: ContactPersonsTableTableInterface) => {
    console.log(contactConnections)

    const [openEditContactPersonModal, setOpenEditContactPersonModal] = useState<boolean>(false)
    const [currentContactPerson, setCurrentContactPerson] = useState<ContactPerson|null>(null)
    const [currentPropertyId, setCurrentPropertyId] = useState<number|null>(null)
    //
    const setContactPersonEditModal = (person: ContactPerson, propertyId: number) => {
        console.log(person)
        setCurrentContactPerson(person);
        setCurrentPropertyId(propertyId);
        setOpenEditContactPersonModal(true);
    }

    const closeEditContactPersonModal = () => {
        setOpenEditContactPersonModal(false)
    }

    const unlinkAssignToProperty = async (data: AssignContactPersonToPropertyFields) => {
        let res = await contactPersonService.unlinkAssignToProperty(data)

        if (res) {
            recallData();
        }
    }

    return(
        <>
            <table className={"ownersTable"}>
                {currentContactPerson && currentPropertyId
                    ? <ContactPersonEditModal
                        close={closeEditContactPersonModal}
                        openCloseState={openEditContactPersonModal}
                        contactPerson={currentContactPerson}
                        recallData={() => recallData()}/>
                    : <></>
                }
                <thead>
                    <tr>
                        <td>Действия</td>
                        <td>Имя</td>
                        <td>Фамилия</td>
                        <td>Личный код</td>
                        <td>Телефон</td>
                        <td>Имейл</td>
                    </tr>
                </thead>
                <tbody>
                {contactConnections.map((con, index) =>
                    <tr key={index}>
                        <td data-label={"Действия"}>
                            <ContactPersonsTableDropDown
                                contactPerson={con.contactPerson}
                                propertyId={propertyId}
                                openEditModal={setContactPersonEditModal}
                                unlinkAssignToProperty={unlinkAssignToProperty}
                            />
                        </td>
                        <td data-label={"Имя"}>{con.contactPerson.name}</td>
                        <td data-label={"Фамилия"}>{con.contactPerson.lastName}</td>
                        <td data-label={"Личный код"}>{con.contactPerson.personalCode}</td>
                        <td data-label={"Телефон"}>{con.contactPerson.phone}</td>
                        <td data-label={"Имейл"}>{con.contactPerson.email}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </>
    );
}