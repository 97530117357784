import {MemberDataForList, MembersListComponentProps} from "../../models/members/member";
import {useSearchParams} from "react-router-dom";
import {UrlService} from "../../services/url";
import {useEffect, useState} from "react";
import {PaginatedResponse} from "../../models/paginated-response/paginated-response";
import {setTitle} from "../../helpers/helper";
import {FilterTable} from "../filter-table/filter-table";
import {Filter} from "../../models/filter-table/filter-table";
import {UserDataForList} from "../../models/users/user";
import {userTableColumns} from "../../models/users/user-table-columns";

const filterFields:Filter[] = [
    {
        type: "input",
        name: "Name",
        placeholder: "Please fill Name",
        fieldKey: "name",
        selectValues: null,
        operands: [
            {name: "Like", value: "like"},
            {name: "=", value: "equal"},
            {name: "!=", value: "not-equal"},
        ]
    },
    {
        type: "input",
        name: "Last Name",
        placeholder: "Please fill Last Name",
        fieldKey: "lastName",
        selectValues: null,
        operands: [
            {name: "Like", value: "like"},
            {name: "=", value: "equal"},
            {name: "!=", value: "not-equal"},
        ]
    },
    {
        type: "input",
        name: "Email",
        placeholder: "Please fill email",
        fieldKey: "email",
        selectValues: null,
        operands: [
            {name: "Like", value: "like"},
            {name: "=", value: "equal"},
            {name: "!=", value: "not-equal"},
        ]
    },
    {
        type: "input",
        name: "Group",
        placeholder: "",
        fieldKey: "group",
        selectValues: [
            {name: "Root", value: "Root"},
            {name: "MemberOfBoard", value: "MemberOfBoard"},
            {name: "Accountant", value: "Accountant"},
        ],
        operands: null
    },
]

export const UsersList = (props: MembersListComponentProps)  => {

    const [searchParams] = useSearchParams();

    const p = (searchParams.get("page") !== null ? parseInt(searchParams.get("page") as string) : 1);
    const op = (searchParams.get("onPage") !== null ? parseInt(searchParams.get("onPage") as string) : 10);
    const url = new UrlService();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(true);
    const [tableData, setTableData] = useState<PaginatedResponse<UserDataForList>|null>(null);
    const [page, setPage] = useState<number>(p);
    const [viewOnPage, setViewOnPage] = useState<number>(10);
    const [filter, setFilter] = useState<string>((searchParams.get("filter") !== null ? searchParams.get("filter") as string : ""));

    useEffect(() => {
        getUsers();
    },[viewOnPage,page,filter]);

    useEffect(() => {
        if (p !== page) {
            setPage(p)
        }
        if (op !== viewOnPage) {
            setViewOnPage(op)
        }
    }, [p, op]);

    const updateFilter = (filter: string) => {
        console.log("Updated filter", filter)
        setFilter(filter);
        setPage(1);
    }

    const changeViewOnPage = (e: React.FormEvent<HTMLSelectElement>) => {
        let rowsCount:number = parseInt(e.currentTarget.value);
        url.updateSearchParams([["page", page+""],["onPage", rowsCount+""]])
        setViewOnPage(rowsCount)
    }

    const changePage = (e:any) => {
        let page:number = parseInt(e.currentTarget.getAttribute("custom-attr"));
        url.updateSearchParams([["page", page+""],["onPage", viewOnPage+""]])
        setPage(page);
    }

    const getUsers = async () => {

        setError(false);
        setLoading(true);

        console.log("Filter", filter)

        try {
            const response = await fetch("/api/v1/private/users/paginated?page="+page+"&onPage="+viewOnPage+(filter !== "" ? "&filter="+filter : ""));
            console.log(response.url)
            if (!response.ok) {
                setError(true);
                return;
            }

            const responseData = await response.json();
            console.log(responseData);
            setTableData(responseData);
            setLoading(false);
            setError(false);
            setTitle("Users list")

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <h1>Members</h1>
            <FilterTable loading={loading} error={error} filters={filterFields} data={tableData} columns={userTableColumns} setPage={changePage} setViewOnPage={changeViewOnPage} recallData={(filter: string) => {updateFilter(filter)}}/>
        </>
        // <></>
    );

}
