import {Button, Form, Input, Modal, notification} from "antd";
import {useState} from "react";
import {FormProps} from "antd/lib/form/Form";
import {
    AddContactPersonFormFields,
    ContactPersonAddModalPropsInterface
} from "../../../models/contact-persons/contact-persons";
import contactPersonService from "../../../services/contact-person-service";

export const AddContactPersonModal = (props: ContactPersonAddModalPropsInterface)  => {

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleCancel = () => {
        setOpen(false);
    }

    const onFinish: FormProps<AddContactPersonFormFields>['onFinish'] = async (values) => {
        console.log(values);
        let [status] = await Promise.all([contactPersonService.add(values, props.propertyId)]);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while adding new connection',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            props.setData()
        }
    };

    const onFinishFailed: FormProps<AddContactPersonFormFields>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)}>Добавить контактное лицо</Button>
            <Modal
            open={open}
            title="Добавить контактное лицо"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    name="contactPerson"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item name="name" label="Имя">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="lastName" label="Фамилия">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="personalCode" label="Личный код">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="phone" label="Номер телефона">
                        <Input placeholder="" type={"phone"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="email" label="Адрес электронной почты">
                        <Input placeholder="" type={"email"} autoComplete="off" autoCapitalize="none" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item name="address" label="Адрес проживания">
                        <Input placeholder="" type={"text"} autoComplete="off" autoCorrect="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Submit</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

}
