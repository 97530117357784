import React, {useCallback} from "react";
import {PageButton, PagesProps} from "../../../../models/filter-table/filter-table";
import "./pages.scss"

const generateButtonsArray = (c:number, n:number): PageButton[] => {

    let pages:PageButton[] = [];
    pages.push({type:0,value:1});

    let start:number = ((n-2) > 1 ? n-2 : (2 > c ? 1 : 2));
    let end:number = ((n+2) >= c ? c : n+2);

    if(start > 3){
        pages.push({type:1,value:"..."});
    }

    if(start >= 2 || end == 3){
        for(let i=start; i <= end; i++){
            pages.push({type:0,value:i});
        }
    }

    if(start !== c && end < c){
        if(end+1 < c){
            pages.push({type:1,value:"..."});
        }

        if(end !== c){
            pages.push({type:0,value:c});
        }
    }

    return pages;
}

export const Pages: React.FC<PagesProps> = ({itemsCount, pagesCount, now, setPage, children}) => {

    const generatePagesButtons = useCallback((c:number, n:number) => {

        let buttons = generateButtonsArray(c,n);

        return(
            <div className={"filterTable__TableContainerPages"}>
                {buttons.map((b, index) => (b.type === 0
                    ? <button key={index} custom-attr={b.value} onClick={setPage} className={(now === b.value ? "active" : "")}>{b.value}</button>
                        : <span key={index}>...</span>
                ))}
                <span>Rows count: {itemsCount}</span>
                {children}
            </div>
        );

    }, [pagesCount,now])

    return(
        <>{generatePagesButtons(pagesCount,now)}</>
    );
}
