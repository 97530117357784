import React, {ReactElement, useState} from "react";
import {FilterTableProps} from "../../models/filter-table/filter-table";
import {Table} from "./table/table";
import {Filter} from "./filter/filter";
import "./filter-table.scss"


export const FilterTable = <T extends object>({error, loading, recallData, filters, data, columns, setPage, setViewOnPage}: FilterTableProps<T>): ReactElement | null => {

    return(
        <div className={"filterTable__Container"}>
            <Filter filters={filters} recallData={recallData}/>
            {(loading || data === null ? (error ? <h1>No search results or error</h1> : <h1>Please wait...</h1> ) :
                <Table data={data} columns={columns} setPage={setPage} setViewOnPage={setViewOnPage} />)}
        </div>
    );
}