import React, {useState} from "react";
import {
    AssignOwnerToPropertyAsMemberFormFields,
    Owner,
    OwnersTableComponentProps, UnlinkOwnerAssignFromPropertyFields
} from "../../../models/owner/owner";
import {EditOwnerModal} from "./edit-owner-modal";
import {OwnersTableDropDown} from "./owners-table-dropdown";
import {AddOwnerModal} from "./add-owner-modal";
import ownerService from "../../../services/owner-service";

export const OwnersTable = ({owners, propertyId, recallData}: OwnersTableComponentProps) => {
    console.log(owners)

    const [openEditOwnerModal, setOpenEditOwnerModal] = useState<boolean>(false)
    const [currentOwner, setCurrentOwner] = useState<Owner|null>(null)
    const [currentPropertyId, setCurrentPropertyId] = useState<number|null>(null)
    const [openAddOwnerModal, setOpenAddOwnerModal] = useState<boolean>(false)

    const setOwnerEditModalData = (owner: Owner, propertyId: number) => {
        console.log(owner)
        setCurrentOwner(owner);
        setCurrentPropertyId(propertyId);
        setOpenEditOwnerModal(true);
    }

    const closeEditOwnerModal = () => {
        setOpenEditOwnerModal(false)
    }

    const assignOwnerAsMember = async (data: AssignOwnerToPropertyAsMemberFormFields) => {
        let res = await ownerService.assignOwnerToPropertyAsMember(data)

        if (res) {
            recallData();
        }
    }

    const clearAssignOwnerAsMember = async (data: AssignOwnerToPropertyAsMemberFormFields) => {
        let res = await ownerService.clearAssignOwnerToPropertyAsMember(data)

        if (res) {
            recallData();
        }
    }

    const unlinkOwnerAssignFromProperty = async (data: UnlinkOwnerAssignFromPropertyFields) => {
        let res = await ownerService.unlinkOwnerAssignFromProperty(data)

        if (res) {
            recallData();
        }
    }

    return(
        <>
            <table className={"ownersTable"}>
                {currentOwner && currentPropertyId
                    ? <EditOwnerModal close={closeEditOwnerModal} openCloseState={openEditOwnerModal} owner={currentOwner}
                                      propertyId={currentPropertyId} recallData={() => recallData()}/>
                    : <></>
                }
                <thead>
                    <tr>
                        <td>Действия</td>
                        <td>Является членом</td>
                        <td>Доля</td>
                        <td>Тип владения</td>
                        <td>Владелец</td>
                        <td>Контактное лицо</td>
                        <td>Телефон</td>
                        <td>Имейл</td>
                    </tr>
                </thead>
                <tbody>
                {owners.map((owner, index) =>
                    <tr key={index} className={owner.isMember ? "member" : ""}>
                        <td data-label={"Действия"}>
                            <OwnersTableDropDown
                                owner={owner}
                                propertyId={propertyId}
                                openEditModal={setOwnerEditModalData}
                                assignOwnerAsMember={assignOwnerAsMember}
                                clearAssignOwnerAsMember={clearAssignOwnerAsMember}
                                unlinkOwnerAssignFromProperty={unlinkOwnerAssignFromProperty}
                            />
                        </td>
                        <td data-label={"Является членом"}>{owner.isMember ? "Да" : "Нет"}</td>
                        <td data-label={"Доля"}>{owner.ownershipShare}</td>
                        <td data-label={"Тип владения"}>{owner.ownershipType}</td>
                        <td data-label={"Владелец"}>{owner.owner.name !== ""
                            ? owner.owner.name + " " + owner.owner.lastName
                            : owner.owner.companyName
                        }</td>
                        <td data-label={"Контактное лицо"}>{owner.owner.contactPersonName !== ""
                            ? owner.owner.contactPersonName + " " + owner.owner.contactPersonLastName
                            : owner.owner.name + " " + owner.owner.lastName
                        }</td>
                        <td data-label={"Телефон"}>{owner.owner.phone}</td>
                        <td data-label={"Имейл"}>{owner.owner.email}</td>
                    </tr>
                )}
                </tbody>
            </table>
            <AddOwnerModal
                propertyId={propertyId}
                recallData={() => {recallData()}}
                openCloseState={openAddOwnerModal}
                close={() => {setOpenAddOwnerModal(false)}}
            />
        </>
    );
}