import {ReactElement, useEffect, useState} from "react";
import {setTitle} from "../../helpers/helper";
import {PropertyFull} from "../../models/property/property";
import {useParams} from "react-router-dom";
import {AddMetersReading} from "./meters-data/add-meters-data";
import {OwnersTable} from "./owners/owners-table";
import {ContactPersonsTable} from "./contact-persons/contact-persons-table";
import {AddContactPersonModal} from "./contact-persons/add-contact-person";
import {ConnectionsList} from "./connections/connections-list";
import {ViewMetersReadings} from "./meters-data/view-meters-readings";
import {DebtsTable} from "./debts/debts-table";
import {AddDebtModal} from "./debts/add-debt-modal";

export const Property = ()  => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(true);
    const [data, setData] = useState<PropertyFull>()
    let { id } = useParams();

    useEffect(() => {getProperty()},[]);

    const getData = () => {
        getProperty()
    }

    const getProperty = async (e?: React.FormEvent<HTMLFormElement>) => {

        setError(false);
        setLoading(true);
        // setPhotoLink(null);

        try {
            const response = await fetch("/api/v1/private/property/by-kadaster/"+id);
            console.log(response.url)
            if (!response.ok) {
                setError(true);
                return;
            }

            const responseData = await response.json();
            console.log(responseData);
            setData(responseData)
            setLoading(false);
            setError(false);
            setTitle("Property list")

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {
                loading
                ? <></>
                : data !== undefined
                    ? <>
                    <h1>{data?.street + "" +data?.houseNumber + " ("+ data?.kadasterNumber+" = "+data?.area+"m2)"}</h1>
                    <p>{data?.city + ", " + data?.county + ", " + data?.country + " (" + data?.createdAt +")"}</p>
                    <h2>Долги</h2>
                    {data?.debts !== null && data?.debts.length > 0
                        ? <DebtsTable debts={data.debts} propertyId={data.id} recallData={() => getProperty()}/>
                        : <></>
                    }
                    <div className={"row"} style={{margin: "30px 0 30px 0"}}>
                        <AddDebtModal propertyId={data.id} recallData={() => {getProperty()}}/>
                    </div>
                    <h2>Владельцы</h2>
                    {data?.owners !== null && data?.owners !== undefined && data?.owners.length > 0
                        ? <OwnersTable propertyId={data.id} owners={data.owners} recallData={() => getProperty()}/>
                        : <p>На данный момент данных о владельцах нет</p>
                    }
                    <h2>Контактные лица</h2>
                    {data?.contactPersons !== null && data?.contactPersons !== undefined && data?.contactPersons.length > 0
                        ? <ContactPersonsTable propertyId={data.id} contactConnections={data.contactPersons} recallData={() => getProperty()}/>
                        : <>
                            <p>На данный момент контактные лица не добавлены</p>
                        </>
                    }
                    <div className={"row"} style={{margin: "30px 0 30px 0"}}>
                        <AddContactPersonModal propertyId={data.id} setData={() => {getProperty()}}/>
                    </div>
                    <h2>Показания</h2>
                    {data !== undefined && data.connections !== null
                        ? <div className={"row"} style={{margin: "0px 0 30px 0"}}>
                            <AddMetersReading propertyId={data.id} connections={data.connections} setData={() => {getProperty()}}/>
                        </div>
                        : <></>
                    }
                    {data !== undefined && data.connections !== null && data.connections.length > 0
                        ? <ViewMetersReadings connections={data.connections} recallData={() => {getProperty()}}/>
                        : <p>На данный момент показаний нет</p>
                    }
                    <h2>Подключение</h2>
                    {data.connections !== null
                        ? <ConnectionsList propertyId={data.id} connections={data.connections} recallData={() => {getProperty()}}/>
                        : <></>
                    }
                </>
                    : <>Нет данных</>
            }

        </>
    );
}