import {
    AddOwnerFormFields,
    AssignOwnerToPropertyAsMemberFormFields, UnlinkOwnerAssignFromPropertyFields,
    UpdateOwnerFormFields
} from "../models/owner/owner";

class OwnerService {

    constructor() {
    }

    async add(data: AddOwnerFormFields, propertyId: number): Promise<any> {
        let response = await fetch("/api/v1/private/owner/", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                name: data.name,
                lastName: data.lastName,
                personalCode: data.personalCode,
                companyName: data.companyName,
                companyRegisterNumber: data.companyRegisterNumber,
                contactPersonName: data.contactPersonName,
                contactPersonLastName: data.contactPersonLastName,
                phone: data.phone,
                email: data.email,
                address: data.address,
                ownershipShare: data.ownershipShare,
                ownershipType: data.ownershipType,
                propertyId: propertyId,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async update(data: UpdateOwnerFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/owner/"+data.id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                id: parseInt(data.id),
                name: data.name,
                lastName: data.lastName,
                personalCode: data.personalCode,
                companyName: data.companyName,
                companyRegisterNumber: data.companyRegisterNumber,
                contactPersonName: data.contactPersonName,
                contactPersonLastName: data.contactPersonLastName,
                phone: data.phone,
                email: data.email,
                address: data.address,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async unlinkOwnerAssignFromProperty(data: UnlinkOwnerAssignFromPropertyFields): Promise<any> {
        let response = await fetch("/api/v1/private/owner/assign-to-property", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: JSON.stringify({
                propertyId: data.propertyId,
                ownerId: data.ownerId,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async assignOwnerToPropertyAsMember(data: AssignOwnerToPropertyAsMemberFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/owner/assign-as-a-member-to-property", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                propertyId: data.propertyId,
                ownerId: data.ownerId,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async clearAssignOwnerToPropertyAsMember(data: AssignOwnerToPropertyAsMemberFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/owner/assign-as-a-member-to-property", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: JSON.stringify({
                propertyId: data.propertyId,
                ownerId: data.ownerId,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

}


let ownerService = new OwnerService();
export default ownerService;