import React, {Reducer, useEffect, useReducer, useState} from 'react';
import {
    AuthorizationAction,
    AuthorizationReducer,
    AuthorizationState
} from "./context/authorization/athorization-reducer";
import AuthorizationContext from "./context/authorization/athorisation-context";
import {Authorisation} from "./components/authorisation/authorisation";
import {ApplicationSkeleton} from "./components/application-skeleton/application-skeleton";
import authService from "./services/authorisation-service";
import "./app.scss"
import {Route, Routes} from "react-router-dom";

function App() {

    const [state, dispatch] = useReducer<Reducer<AuthorizationState, AuthorizationAction>, AuthorizationState>(AuthorizationReducer, {user: null, isLoggedIn: false, loading: true}, () => ({user: null, isLoggedIn: false, loading: true}));

    useEffect(() => {
        authService.setAuthDispatcher(dispatch)
        authService.isLoggedIn();
    }, [dispatch]);

    return (
        <AuthorizationContext.Provider value={{state, dispatch}}>
            {
                state.loading
                ? <div className="loadingContainer">
                    <div className="loader"></div>
                    </div>
                : state.isLoggedIn
                    ? state.user !== null
                        ? <ApplicationSkeleton user={state.user}/>
                            : <h2>Unexpected authorization data received</h2>
                    : <>
                            <Routes>
                                {/*<Route key={0} index={true} path={"/camera"} element={<MyCamera/>}/>*/}
                                <Route key={0} index={true} path={"/*"} element={<Authorisation/>}/>
                            </Routes>
                        </>
            }
            {/*{*/}
            {/*    <ApplicationSkeleton user={{userName: "dscd", name: "Sergei", lastName: "Russak"}}/>*/}
            {/*}*/}
        </AuthorizationContext.Provider>
    );
}

export default App;
