import {OwnersTableDropDownComponentProps} from "../../../models/owner/owner";
import React from "react";
import {Dropdown, MenuProps, Popconfirm, Space} from "antd";
import {Icon} from "../../icons/icon";
import modal from "antd/lib/modal";

export const OwnersTableDropDown = (props: OwnersTableDropDownComponentProps) => {

    const confirm = (title: string, content: string, okText: string, cancelText: string, onConfirm: () => void) => {
        modal.confirm({
            title: title,
            content: content,
            okText: okText,
            cancelText: cancelText,
            onOk:  onConfirm
        });
    };

    const items: MenuProps['items'] = [
        {
            label: <Space style={{width: "100%"}} onClick={() => {props.openEditModal(props.owner.owner, props.propertyId)}}>Редактировать</Space>,
            key: '0',
        },
        {
            label: ( props.owner.isMember
                ? <Space onClick={() => {
                        confirm(
                            "Удалить членство",
                            "Вы уверены в этом действии?",
                            "Да",
                            "Нет", () => {props.clearAssignOwnerAsMember({ownerId: props.owner.ownerId, propertyId: props.propertyId})}
                        )}} style={{width: "100%"}}>Удалить членство</Space>
                : <Space onClick={() => {
                        confirm(
                            "Сделать членом",
                            "Вы уверены в этом действии?",
                            "Да",
                            "Нет", () => {props.assignOwnerAsMember({ownerId: props.owner.ownerId, propertyId: props.propertyId})}
                        )}} style={{width: "100%"}}>Сделать членом</Space>),
            key: '1',
        },
        {
            label: <Space onClick={() => {
                        confirm(
                            "Отвязать от собственности",
                            "Вы уверены в этом действии?",
                            "Да",
                            "Нет", () => {props.unlinkOwnerAssignFromProperty({ownerId: props.owner.ownerId, propertyId: props.propertyId})}
                        )}} style={{width: "100%"}}>Отвязать от собственности</Space>,
            key: '2',
        },
        {
            label: <Space onClick={() => {confirm("Title", "modal content", "Подтвердить", "Отменить", () => {console.log("dcsdcs")})}} style={{width: "100%"}}>Кнопка</Space> ,
            key: '3',
        },
        {
            label: <Space onClick={() => {confirm("Title2", "modal content2", "Подтвердить", "Отменить", () => {console.log("dcsdcs2")})}} style={{width: "100%"}}>Кнопка2</Space> ,
            key: '4',
        },
    ];

    return(
        <>
            <Dropdown menu={{items}} trigger={['click']}>
                <button>
                    <Icon iconName={"More"}/>
                </button>
            </Dropdown>
        </>
    );
}