import {FieldData, FormFilter, FormFilters, UrlFilterPiece} from "../../models/filter-table/filter-table";
import {cloneElement} from "react";

export const encodeFilter: (data: FormFilters) => string = (data: FormFilters):string => {
    let res:string[] = [];

    for(let key in data) {

        let f = ""
        let d = data[key] as FormFilter;

        if(d.value === undefined || d.value === "undefined" || d.value === ""){
            continue
        }

        if(d.operand !== null && d.operand !== undefined) {
            f += "op:"+d.operand;
        }

        res.push("k:"+key+","+(f !== "" ? f+"," : "")+"v:"+d.value);
    }

    return "{"+res.join(";")+"}" as string;
}

export const decodeFilter: (data: string) => FieldData[] = (data: string):FieldData[] => {

    if(data === "{}"){
        return []
    }

    let res: FieldData[] = [];
    let filterDefObj:FieldData = {name: [], value: ""}

    data = data.replaceAll("{", "");
    data = data.replaceAll("}", "");

    let elements = data.split(";")

    elements.map((el) => {

        let filterData = el.split(",");
        let filterTmpData:UrlFilterPiece = {k: "", op: "", v: ""}

        filterData.map((el) => {

            let filterPiece = el.split(":");
            switch (filterPiece[0]) {
                case 'k':
                    filterTmpData.k = filterPiece[1];
                    break;
                case 'op':
                    filterTmpData.op = filterPiece[1];
                    break;
                case 'v':
                    filterTmpData.v = filterPiece[1];
                    break;
            }
        });

        let filterOperand = structuredClone(filterDefObj)
        filterOperand.name = [filterTmpData.k, "operand"];
        filterOperand.value = filterTmpData.op;

        let filterValue = structuredClone(filterDefObj)
        filterValue.name = [filterTmpData.k, "value"];
        filterValue.value = filterTmpData.v;

        res.push(filterOperand)
        res.push(filterValue)
    })

    return res;
}