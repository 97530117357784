import {useEffect, useState} from "react";
import {setTitle} from "../../helpers/helper";
import {useParams} from "react-router-dom";
import {User} from "../../models/users/user";

export const ViewUser = ()  => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(true);
    const [data, setData] = useState<User>()
    let { id } = useParams();

    useEffect(() => {getUser()},[]);

    const getData = () => {
        getUser()
    }

    const getUser = async () => {

        setError(false);
        setLoading(true);

        try {
            const response = await fetch("/api/v1/private/user/"+id);
            console.log(response.url)
            if (!response.ok) {
                setError(true);
                return;
            }

            const responseData = await response.json();
            console.log(responseData);
            setData(responseData)
            setLoading(false);
            setError(false);
            setTitle("User - " + responseData.name + " " + responseData.lastName);

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {
                data !== null && data !== undefined
                ? <>
                    <h2>{data.name + " " + data.lastName}</h2>
                    <div className={"new-card"}>
                        <div className={"card-text-row"}>
                            <div className={"card-text-block size--1-3"}>
                                <span>Имаил</span>
                                <p>{data.email}</p>
                            </div>
                            <div className={"card-text-block size--1-3"}>
                                <span>Группа</span>
                                <p>{data.group}</p>
                            </div>
                            <div className={"card-text-block size--1-3"}>
                                <span>Дата создания</span>
                                <p>{data.createdAt}</p>
                            </div>
                        </div>
                    </div>
                </>
                : <></>
            }
        </>
    );
}