import React, {useEffect, useState} from "react";
import {Connection, ConnectionsListComponentProps} from "../../../models/connection/connection";
import {AddConnectionModal} from "./add-connection-modal";
import {ConnectionCard} from "./connection-card";

export const ConnectionsList = (props: ConnectionsListComponentProps) => {

    const [propertyId, setPropertyId] = useState<number>(props.propertyId);
    const [connections, setConnections] = useState<Connection[]>(props.connections);

    useEffect(() => {
        setPropertyId(props.propertyId);
        setConnections(props.connections);
    }, [propertyId, connections]);

    return(
        <>
            <div className="row" style={{marginBottom: "30px"}}>
                <AddConnectionModal propertyId={propertyId} recallData={() => {props.recallData()}}/>
            </div>
            {
                connections.length !== undefined && connections.length > 0
                    ? <div className="cards">
                        { connections.map((connection: Connection, index: number) =>
                            <ConnectionCard connection={connection} recallData={() => {props.recallData()}}/>
                        )}</div>
                : <>Нет данных</>
            }
        </>
    );
}