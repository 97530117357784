import React, {useEffect, useState} from "react";
import {Connection, ConnectionsCardComponentProps} from "../../../models/connection/connection";
import {AddConnectionModal} from "./add-connection-modal";
import {Icon} from "../../icons/icon";
import {Files} from "../../../models/file/file";
import {PhotoModal} from "./photo-modal";
import {UploadPhoto} from "./upload-photo/upload-photo";
import {blockBody, switchZoom} from "../../../helpers/helper";
import {EditConnectionModal} from "./edit-connection-modal";

export const ConnectionCard = (props: ConnectionsCardComponentProps) => {

    const [connection, setConnection] = useState<Connection>(props.connection);
    const [photoLink, setPhotoLink] = useState<string | null>(null);
    const [openPhotoModal, setOpenPhotoModal] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);

    useEffect(() => {
        setConnection(props.connection);
    }, [connection]);

    const openPhotoModalHandler = () => {
        setOpenPhotoModal(true);
        switchZoom(true);
        blockBody(true);
    }

    const closePhotoModalHandler = () => {
        setOpenPhotoModal(false);
        switchZoom(false);
        setPhotoLink(null);
        blockBody(false);
    }

    return(
        <>
            <div className="card">
                <div className="card-header-block">
                    <div className="card-header-content"><h1 style={{marginTop: "0px"}}>{connection.connectionName}</h1></div>
                    <div className="card-header-buttons">{<button onClick={() => {setOpenEditModal(true)}}><Icon iconName={"Edit"}/></button>}</div>
                </div>
                <table>
                    <tr>
                        <td>Место подключения:</td>
                        <td>{connection.connectionPoint}</td>
                    </tr>
                    <tr>
                        <td>Тип подключения:</td>
                        <td>{connection.connectionType}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>({connection.connectionTypeComment})</td>
                    </tr>
                    <tr>
                        <td>Номинал вводного автомата:</td>
                        <td>{connection.inputSwitchRating}</td>
                    </tr>
                    <tr>
                        <td>Производитель вводного автомата:</td>
                        <td>{connection.inputSwitchManufacturer}</td>
                    </tr>
                    <tr>
                        <td>Тип пломбы на вводном автомате:</td>
                        <td>{connection.inputSwitchSealType}</td>
                    </tr>
                    <tr>
                        <td>Номер пломбы:</td>
                        <td>{connection.inputSwitchSealSerialNumber}</td>
                    </tr>
                    <tr>
                        <td>Акт замены счетчика:</td>
                        <td>{connection.hasElectricityMeterActOfReplacement ? "Да" : "Нет"}</td>
                    </tr>
                    <tr>
                        <td>Состояние счетчика:</td>
                        <td>{connection.electricityMeterState}</td>
                    </tr>
                    <tr>
                        <td>Тип счетчика:</td>
                        <td>{connection.electricityMeterType}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>({connection.electricityMeterComment})</td>
                    </tr>
                    <tr>
                        <td>Серийный номер счетчика:</td>
                        <td>{connection.electricityMeterSerialNumber}</td>
                    </tr>
                    <tr>
                        <td>Тип пломбы на счетчике:</td>
                        <td>{connection.electricityMeterSealType}</td>
                    </tr>
                    <tr>
                        <td>Номер пломбы на счетчике:</td>
                        <td>{connection.electricityMeterSealSerialNumber}</td>
                    </tr>
                    <tr>
                        <td>Запись создана:</td>
                        <td>{connection.createdAt}</td>
                    </tr>
                </table>
                {connection.files !== null && connection.files.length > 0
                    ? <div className={"imagesList"}>
                        {connection.files.map((file: Files, index: number) =>
                            <img src={"/api/v1/private/get-thumbnail-image/"+file.id} key={index} onClick={() => {setPhotoLink("/api/v1/private/get-big-image/"+file.id); openPhotoModalHandler();} }/>
                        )}
                    </div>
                    : <></>
                }
                <PhotoModal photoLink={photoLink} closeHandler={() => closePhotoModalHandler()} open={openPhotoModal}/>
                <UploadPhoto connectionId={connection.id} recallData={() => {props.recallData()}}/>
            </div>
            <EditConnectionModal
                connection={connection}
                openState={openEditModal}
                close={() => {setOpenEditModal(false)}}
                recallData={() => {props.recallData()}}
            />
        </>
    );
}