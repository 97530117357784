import React, {useState} from "react";
import {AddMeterDataModalComponentProps, AddMeterDataFormFields} from "../../../models/meters-data/meters-data";
import {Button, Form, Input, Modal, notification, Select} from "antd";
import connectionService from "../../../services/connection-service";
const { Option } = Select;

export const AddMetersReading = ({propertyId, setData, connections}: AddMeterDataModalComponentProps) => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
        form.resetFields();
    }

    const onSubmit = async () => {
        console.log(form.getFieldsValue());
        let status = await connectionService.addMeterReading(form.getFieldsValue() as AddMeterDataFormFields);
        if (!status) {
            api.error({
                message: 'Error',
                description:
                'Error while adding new connection',
                showProgress: true,
                pauseOnHover: true,
                duration: 7,
            });
        } else {
            setData()
        }
    }

    const onSubmitFail = () => {
        console.log(form.getFieldsValue())
    }

    return(
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setOpen(true)}>Добавить показания</Button>
            <Modal
            open={open}
            title="Добавить подключение"
            onCancel={handleCancel}
            footer={[]}
            >
                <Form
                    form={form}
                    name="addMetersReading"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onSubmit}
                    onFinishFailed={onSubmitFail}
                >
                    <Form.Item name="connectionId" label="Подключение" rules={[{ required: true }]}>
                        <Select>
                            {connections.map(connection => (
                                <Option value={connection.id}>{connection.connectionName}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="meterReading" label="Показания">
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item name="propertyId" hidden={true} initialValue={propertyId}>
                        <Input placeholder="" type={"number"} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginRight: "10px"}}>Подать</Button>
                        <Button onClick={handleCancel}>Отмена</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}