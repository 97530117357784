import {AddDebtsFormFields, UpdateDebtFormFields} from "../models/debts/debt";

class DebtsService {

    constructor() {
    }

    async add(d: AddDebtsFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/debts/", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                propertyId: d.propertyId,
                systemType: d.systemType,
                type: d.type,
                sum: parseFloat(d.sum),
                comment: d.comment
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async update(d: UpdateDebtFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/debts/"+d.id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                propertyId: d.propertyId,
                systemType: d.systemType,
                type: d.type,
                sum: parseFloat(d.sum),
                comment: d.comment
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async delete(id: number): Promise<any> {
        let response = await fetch("/api/v1/private/debts/"+id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: "",
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }



}


let debtsService = new DebtsService()
export default debtsService;