import {AddConnectionFormFields, EditConnectionFormFields} from "../models/connection/connection";
import {AddMeterDataFormFields} from "../models/meters-data/meters-data";

class ConnectionService {

    constructor() {
    }

    async add(con: AddConnectionFormFields, propertyId: number): Promise<any> {
        let response = await fetch("/api/v1/private/connection/add-to-property", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                propertyId: propertyId,
                connectionName: con.connectionName,
                connectionPoint: con.connectionPoint,
                connectionType: con.connectionType,
                connectionTypeComment: con.connectionTypeComment,
                inputSwitchRating: con.inputSwitchRating,
                inputSwitchManufacturer: con.inputSwitchManufacturer,
                inputSwitchSealType: con.inputSwitchSealType,
                inputSwitchSealSerialNumber: con.inputSwitchSealSerialNumber,
                hasElectricityMeterActOfReplacement: con.hasElectricityMeterActOfReplacement,
                electricityMeterState: con.electricityMeterState,
                electricityMeterType: con.electricityMeterType,
                electricityMeterComment: con.electricityMeterComment,
                electricityMeterSerialNumber: con.electricityMeterSerialNumber,
                electricityMeterSealType: con.electricityMeterSealType,
                electricityMeterSealSerialNumber: con.electricityMeterSealSerialNumber,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async edit(con: EditConnectionFormFields): Promise<any> {
        let response = await fetch("/api/v1/private/connection/"+con.id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                connectionName: con.connectionName,
                connectionPoint: con.connectionPoint,
                connectionType: con.connectionType,
                connectionTypeComment: con.connectionTypeComment,
                inputSwitchRating: con.inputSwitchRating,
                inputSwitchManufacturer: con.inputSwitchManufacturer,
                inputSwitchSealType: con.inputSwitchSealType,
                inputSwitchSealSerialNumber: con.inputSwitchSealSerialNumber,
                hasElectricityMeterActOfReplacement: con.hasElectricityMeterActOfReplacement,
                electricityMeterState: con.electricityMeterState,
                electricityMeterType: con.electricityMeterType,
                electricityMeterComment: con.electricityMeterComment,
                electricityMeterSerialNumber: con.electricityMeterSerialNumber,
                electricityMeterSealType: con.electricityMeterSealType,
                electricityMeterSealSerialNumber: con.electricityMeterSealSerialNumber,
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async addMeterReading(data: AddMeterDataFormFields): Promise<any> {
        console.log(data)
        let response = await fetch("/api/v1/private/meters-data", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                propertyId: data.propertyId,
                connectionId: data.connectionId,
                meterReading: parseInt(data.meterReading),
            }),
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

    async deleteMeterReading(id: number): Promise<any> {
        let response = await fetch("/api/v1/private/meters-data/"+id, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "DELETE",
            body: "",
        })

        if (response.status === 200) {
            // let respJson = await response.json()
            return true
        } else {
            return false
        }
    }

}


let connService = new ConnectionService()
export default connService;