export interface Debt {
    id: number
	propertyId: number
	systemType: string
	type: string
	sum: number
	comment: string
	createdAt: string
	updatedAt: string
}

export interface DebtsTableComponentProps {
    debts: Debt[],
    propertyId: number,
    recallData: () => void
}

export interface AddDebtsModalComponentProps {
    propertyId: number,
    recallData: () => void
}

export interface AddDebtsFormFields {
    propertyId: number
	systemType: string
	type: string
	sum: string
	comment: string
}

export interface UpdateDebtsModalComponentProps {
    close: () => void,
    openCloseState: boolean
    debt: Debt,
    recallData: () => void
}

export interface UpdateDebtFormFields {
    id: number
    propertyId: number
	systemType: string
	type: string
	sum: string
	comment: string
}

export interface DebtsTableDropDownComponentProps {
    debt: Debt,
    openEditModal: (debt: Debt) => void,
    recallData: () => void
}

export const DebtsSystemTypes: { [key: string]: string } = {
    membershipFee: "Членский взнос",
    electricity: "Элекстричество",
    waterKey: "Ключь от скважены",
    other: "Другое",
}